.gallery-container {
    padding: 20px;
}

.trip-card {
    cursor: pointer;
    transition: transform 0.3s;
}

.trip-card:hover {
    transform: scale(1.05);
}

.trip-card img {
    height: 200px;
    object-fit: cover;
}

.trip-card .card-body {
    text-align: center;
}
