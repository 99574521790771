/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

/* Global Reset */
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
}

/* Root Variables */
:root {
  --color-bg: #000000;
  --color-bg-variant: rgb(0, 255, 175);
  --color-primary: #ffff00;
  --color-primary-variant: #ff0000;
  --color-black: rgb(0, 0, 0);
  --color-light: rgba(255, 255, 255, 0.6);
  --color-white: #ffffff;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 78%;

  --transition: all 400ms ease;
}

/* Basic HTML Styles */
html {
  scroll-behavior: smooth;
}

/* Hide Scrollbar */
::-webkit-scrollbar {
  display: none;
}

/* Body Styles */
body {
  font-family: "Poppins", sans-serif;
  /*background: var(--color-bg);*/
  background: #beb097;
  color: var(--color-white);
  line-height: 1.7;
}

/* Container Styles */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

/* Heading Styles */
h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

/* Section Styles */
section {
  margin-top: 8rem;
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

/* Text Light Style */
.text-light {
  color: var(--color-light);
}

/* Anchor Styles */
a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

/* Button Styles */
.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-white);
  color: var(--color-bg);
}

/* Image Styles */
img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* Media Queries for Tablets */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

/* Media Queries for Phones */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-bottom: 2rem;
  }
}
