/* Custom styles for carousel */
.carousel {
    width: 100%; /* Make the carousel span the full width of the screen */
    height: auto;
    margin: 0 auto; /* Center the carousel horizontally */
}

.carousel-item {
    width: 100%;
    height: 80vh;
    display: flex; /* Ensure the item content is centered */
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensure no overflow */

}

.carousel-item img {
    width: 100%; /* Make images fill the carousel container */
    height: 100%;
    object-fit: fill;
}

.carousel-control-prev,
.carousel-control-next {
    width: 5%; /* Set width for control buttons */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    /* Customize control button icons */
    background-image: none;
}

.carousel-control-prev-icon:before {
    /* Customize previous button icon */
    content: '‹'; /* Unicode character for previous icon */
    font-size: 3rem; /* Icon size */
    color: #000; /* Icon color */
}

.carousel-control-next-icon:before {
    /* Customize next button icon */
    content: '›'; /* Unicode character for next icon */
    font-size: 3rem; /* Icon size */
    color: #000; /* Icon color */
}

.carousel-indicators {
    bottom: 10px; /* Position indicators at the bottom */
}

.carousel-indicators li {
    background-color: #ff0707; /* Indicator background color */
    border: 2px solid #000; /* Indicator border */
}

.carousel-indicators .active {
    background-color: #ff0000; /* Active indicator background color */
}

/* Responsive adjustments */
@media (max-width: 767px) {
    .carousel-item {
        height: 40vh; /* Set height to 40% of the viewport height on mobile */
    }
}