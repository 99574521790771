/* Navbar Container */
.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 1rem; /* Adjust padding */
    background-color: #beb097; /* Change background color */
    color: #fff; /* Change text color */
}

/* Navbar Header (Brand + Toggle) */
.navbar-header {
    display: flex;
    align-items: center;
}

/* Navbar Brand Logo */
.navbar-brand img {
    margin-right: 5px; /* Adjust spacing */
    height: auto;
    width: 10%;
}

/* Navbar Brand Text */
.navbar-brand span {
    font-size: 1.3rem; /* Adjust font size */
    line-height: 1;
}

/* Navbar Links */
.nav-link {
    color: #fff; /* Text color */
    margin-right: 20px; /* Spacing between links */
    font-size: 1rem; /* Adjust font size */
}

/* Center the links horizontally */
.navbar-nav {
    display: flex; /* Ensure flex layout */
    justify-content: flex-end; /* Align items to the right */
    align-items: center; /* Align items vertically */
}

/* Navbar Links Hover */
.nav-link:hover {
    color: #ccc; /* Text color on hover */
}

/* Burger Menu Icon */
.navbar-toggler {
    border: none; /* Remove default border */
    background: transparent; /* Make background transparent */
    margin-left: auto; /* Push it to the right */
    padding: 0; /* Remove default padding */
}

/* Mobile Styles */
@media (max-width: 767px) {
    .navbar-container {
        flex-direction: column;
    }

    .navbar-header {
        display: flex;
        align-items: center;
    }

    .navbar-brand img {
        margin-right: 5px; /* Adjust spacing */
        height: auto;
        width: 25%;
    }
}

